

















































import { Component, Prop, Vue } from "vue-property-decorator";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import EmployeesHRDocumentsPayload from "@/dto/archive/EmployeesHRDocumentsPayload";
import EmployeeService from "@/services/EmployeeService";
import { ifValid, processError } from "@/utils/ComponentUtils";
import { namespace } from "vuex-class";
import { EmployeesHRDocumentType } from "@/constants/DocumentConstants";
import SelectOption from "@/components/common/SelectOption";

const AppModule = namespace("App");

@Component({
  components: {
    SingleFileHolder,
    PortalDate,
    PortalSelect,
    PortalInput
  }
})
export default class AddEmployeeDocumentModal extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  private onSuccess!: () => void

  private payload = new EmployeesHRDocumentsPayload();
  private isValidFile = true;

  addEmployeeDocument(): void {
    this.isValidFile = !!this.payload.file;
    ifValid(this, () => {
      if (this.isValidFile) {
        this.startLoading();
        EmployeeService.addHRDocument(this.employeeId, this.payload).then(
          () => {
            this.stopLoading();
            this.$modal.hideAll();
            this.onSuccess();
          },
          error => {
            processError(error, this);
            this.stopLoading();
          }
        );
      }
    });
  }

  buildTypes(): Array<SelectOption> {
    return Object.entries(EmployeesHRDocumentType)
      .map(type => SelectOption.builder().title(type[1]).value(type[0]).build());
  }

  get employeeId(): string {
    return this.$route.params.employeeId;
  }

}
