
import {Component, Vue} from "vue-property-decorator";
import EmployeeDetails from "@/components/payroll/business/employments/employee/EmployeeDetails.vue";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import EmployeeService from "@/services/EmployeeService";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import { processError } from "@/utils/ComponentUtils";
import Workspaces from "@/state/Workspaces";
import VacationView from "@/components/payroll/vacation/EmploymentVacationList.vue";
import Tariffication from "@/components/payroll/business/employments/employee/Tariffication.vue";
import EmployeePayrollDetails from "@/components/payroll/business/employments/employee/EmployeePayrollDetails.vue";
import EmployeePaystub from "@/components/payroll/business/employments/employee/EmployeePaystub.vue";
import PoliciesView from "@/components/payroll/policies/PoliciesView.vue";
import EmploymentSickLeaveList from "@/components/payroll/sickLeave/EmploymentSickLeaveList.vue";
import EmploymentCard from "@/components/payroll/business/employments/EmploymentCard.vue";
import {CounterpartyType} from "@/constants/CounterpartyType";
import IndividualForeignContractor
	from "@/components/payroll/business/employments/foreignContractors/individual/IndividualForeignContractorDetails.vue";
import Compensation from "@/components/payroll/business/employments/foreignContractors/individual/Compensation.vue";
import EmploymentVacationList from "@/components/payroll/vacation/EmploymentVacationList.vue";
import BusinessForeignContractor
	from "@/components/payroll/business/employments/foreignContractors/business/BusinessForeignContractor.vue";
import EmploymentDocuments from "@/components/payroll/business/employments/employee/EmploymentDocuments.vue";

const AppModule = namespace("App");

@Component({
	components: {Details: EmployeeDetails}
})
export default class EmployeeCard extends EmploymentCard<EmployeeDto> {


	get components(): Map<string, typeof Vue> {
		const components: Map<string, typeof Vue> = new Map<string, typeof Vue>()
		if (this.getEmployment) {
			components.set(`${this.getEmployment.details.name}`, EmployeeDetails)
			if (Workspaces.isUserSublimeEmployee) {
				components.set("Tariffication", Tariffication)
				components.set("Payroll details", EmployeePayrollDetails)
				components.set("Paystub", EmployeePaystub)
			}
			components.set("Policies", PoliciesView)
			components.set("Vacations", VacationView)
			components.set("Sick Leaves", EmploymentSickLeaveList)
			components.set("Employment documents", EmploymentDocuments)
		}
		return components
	}



	public created() {
		super.created()
		this.setTitle("Employee Card")
	}

}

