


























import {Component, Prop, Vue} from "vue-property-decorator";
import {TaxData} from "@/dto/payroll/Tax";
import {IEmployment} from "@/dto/payroll/IEmployment";
import {OverrideTaxRatePayload} from "@/dto/payroll/Tariffication";
import PortalInput from "@/components/common/PortalInput.vue";
import {ifValid} from "@/utils/ComponentUtils";
import {namespace} from "vuex-class";
import TarifficationService from "@/services/TarifficationService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";

const AppModule = namespace("App");

@Component({
	components: {PortalInput}
})
export default class TaxRateOverrideModal extends Vue {
	@Prop()
	private taxData!: TaxData;

	@Prop()
	private employment!: IEmployment;

	@Prop()
	private onSuccess!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	@AppModule.Action
	private startLoading!: () => void

	private message: string | null = null;

	private successful = false;

	private req: OverrideTaxRatePayload = new OverrideTaxRatePayload();

	public created(): void {
		this.req.taxHolderId = this.employment.id;
		if (this.taxData.overrideTax?.taxRate) {
			this.req.taxRate = this.taxData.overrideTax.taxRate
		} else {
			this.req.taxRate = this.taxData.defaultTax.taxRate
		}
		if (this.taxData.overrideTax?.amount) {
			this.req.amount = this.taxData.overrideTax.amount
		} else {
			this.req.amount = this.taxData.defaultTax.amount
		}
		this.req.taxUuid = this.taxData.uuid
	}

	private override(): void {
		ifValid(this, () => {
			this.startLoading();
            if (this.req.taxRate) {
              this.req.taxRate = Number(this.req.taxRate.toString().replaceAll(",", "."))
            }
            if (this.req.amount) {
              this.req.amount = Number(this.req.amount.toString().replaceAll(",", "."))
            }
			TarifficationService.overrideTaxRate(this.req).then(
				res => {
					this.onSuccess();
					this.$modal.hideAll();
					this.stopLoading();
				},
				err => this.processError(err)
			)
		})
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}
}

