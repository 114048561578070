



























































































































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {PAY_PERIOD_TYPE_OPTIONS, PAY_TYPE_OPTIONS, PayPeriodType, PayType} from "@/constants/TarifficationConstants";
import SelectOption from "@/components/common/SelectOption";
import {prettyEnum} from "@/utils/StringUtils";
import TarifficationService from "@/services/TarifficationService";
import {TaxBatch, TaxData, TaxHolderType} from "@/dto/payroll/Tax";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {UpdateTarifficationPayload} from "@/dto/payroll/Tariffication";
import {round} from "@popperjs/core/lib/utils/math";
import TaxRateOverrideModal from "@/components/payroll/business/employments/employee/TaxRateOverrideModal.vue";
import {ifValid} from "@/utils/ComponentUtils";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {Week} from "@/constants/DateTimeConstants";
import {DateTime} from "luxon";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import {ContractType, contractTypeOptions} from "@/constants/EmploymentType";
import {EmploymentVue} from "@/components/payroll/business/employments/EmploymentVue";

const AppModule = namespace("App");

@Component({
	methods: {contractTypeOptions: contractTypeOptions, round, prettyEnum},
	computed: {
		ContractType() {
			return ContractType
		},
		DateTime() {
			return DateTime
		},
		PayPeriodType() {
			return PayPeriodType
		},
		SelectOption() {
			return SelectOption
		},
		PayType() {
			return PayType
		},
		PAY_PERIOD_TYPE_OPTIONS() {
			return PAY_PERIOD_TYPE_OPTIONS
		},
		PAY_TYPE_OPTIONS() {
			return PAY_TYPE_OPTIONS
		}
	},
	components: {PortalRadio, PortalCheckbox, PortalSelect, PortalInput, BTPortalCollapse}
})
export default class Tariffication extends EmploymentVue<EmployeeDto> {
	@Prop()
	employment!: EmployeeDto

	private searchableTaxUuid = "";

	private message: string | null = null;

	private successful = false;

	private updatePayload: UpdateTarifficationPayload = new UpdateTarifficationPayload();

	@AppModule.Action
	private stopLoading!: () => void

	@AppModule.Action
	private startLoading!: () => void

	private tariffiactionIsOpen = true;
	private employeeTaxesIsOpen = true;

	private taxBatch: TaxBatch | null = null

	private employeeTaxBatch = new TaxBatch()

	private businessTaxBatch = new TaxBatch()

	private buildWeekDayOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title(prettyEnum(Week.SUNDAY)).value(Week.SUNDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.MONDAY)).value(Week.MONDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.TUESDAY)).value(Week.TUESDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.WEDNESDAY)).value(Week.WEDNESDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.THURSDAY)).value(Week.THURSDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.FRIDAY)).value(Week.FRIDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.SATURDAY)).value(Week.SATURDAY).build(),
		]
	}

	private update(): void {
		this.updatePayload.rate = Number(this.updatePayload.rate.toString().replaceAll(",", "."))
		this.updatePayload.overtimeRate = Number(this.updatePayload.overtimeRate.toString().replaceAll(",", "."))
		ifValid(this, () => {
			this.startLoading();
			this.updatePayload.taxDefinitions = this.assembleTaxDefs();
			TarifficationService.updateTarifficationInfo(this.updatePayload).then(
				res => {
					this.successful = true;
					this.message = "";
					this.stopLoading()
				},
				err => this.processError(err)
			)
		}, () => console.log(this))
	}

	private assembleTaxDefs(): Array<string> {
		let result: Array<string> = [];
		result.push(...this.employeeTaxBatch.federalTaxes.map(it => it.uuid))
		result.push(...this.employeeTaxBatch.stateTaxes.map(it => it.uuid))
		result.push(...this.employeeTaxBatch.cityTaxes.map(it => it.uuid))
		result.push(...this.businessTaxBatch.federalTaxes.map(it => it.uuid))
		result.push(...this.businessTaxBatch.stateTaxes.map(it => it.uuid))
		result.push(...this.businessTaxBatch.cityTaxes.map(it => it.uuid))
		return result;
	}

	public created(): void {
		this.updatePayload.payType = this.employment.tariffication.payType
		this.updatePayload.payPeriodType = this.employment.tariffication.payPeriodType
		this.updatePayload.rate = this.employment.tariffication.rate
		this.updatePayload.taxHolderId = this.employment.id!
		this.updatePayload.overtimeRate = this.employment.tariffication.overtimeRate
		this.updatePayload.payPeriodStart = this.employment.tariffication.payPeriodStart;
		this.updatePayload.payPeriodEnd = this.employment.tariffication.payPeriodEnd;
		this.updatePayload.zoneId = this.employment.tariffication.zoneId
		this.updatePayload.contractType = this.employment.tariffication.contractType
		this.getTaxes()
	}

	private getTaxes(): void {
		this.startLoading()
		TarifficationService.getTaxesByEmploymentId(this.employment.id!).then(
			res => {
				this.taxBatch = res.data;
				this.assembleBusinessTaxBatch();
				this.assembleEmployeeTaxBatch()
				this.successful = true;
				this.stopLoading()
			},
			err => this.processError(err)
		)
	}

	private assembleBusinessTaxBatch() {
		let businessFederalTax = this.businessTaxes.federalTaxes.filter(tax => this.employment.tariffication.taxDefs.indexOf(tax.uuid) >= 0)
		let businessStateTax = this.businessTaxes.stateTaxes.filter(tax => this.employment.tariffication.taxDefs.indexOf(tax.uuid) >= 0)
		let businessCityTax = this.businessTaxes.cityTaxes.filter(tax => this.employment.tariffication.taxDefs.indexOf(tax.uuid) >= 0)
		if (businessFederalTax.length > 0) {
			this.businessTaxBatch.federalTaxes = businessFederalTax
		}
		if (businessStateTax.length > 0) {
			this.businessTaxBatch.stateTaxes = businessStateTax
		}
		if (businessCityTax.length > 0) {
			this.businessTaxBatch.cityTaxes = businessCityTax
		}
	}

	private assembleEmployeeTaxBatch() {
		let employeeFederalTaxes = this.employeeTaxes.federalTaxes.filter(tax => this.employment.tariffication.taxDefs.indexOf(tax.uuid) >= 0)
		let employeeStateTaxes = this.employeeTaxes.stateTaxes.filter(tax => this.employment.tariffication.taxDefs.indexOf(tax.uuid) >= 0)
		let employeeCityTax = this.employeeTaxes.cityTaxes.filter(tax => this.employment.tariffication.taxDefs.indexOf(tax.uuid) >= 0)
		if (employeeFederalTaxes.length > 0) {
			this.employeeTaxBatch.federalTaxes = employeeFederalTaxes
		}
		if (employeeStateTaxes.length > 0) {
			this.employeeTaxBatch.stateTaxes = employeeStateTaxes
		}
		if (employeeCityTax.length > 0) {
			this.employeeTaxBatch.cityTaxes = employeeCityTax
		}
	}

	get employeeTaxes(): TaxBatch {
		let res: TaxBatch = new TaxBatch();
		if (this.taxBatch) {
			res.federalTaxes = this.taxBatch.federalTaxes.filter(tax => tax.taxHolderType == TaxHolderType.EMPLOYEE)
			res.stateTaxes = this.taxBatch.stateTaxes.filter(tax => tax.taxHolderType == TaxHolderType.EMPLOYEE)
			res.cityTaxes = this.taxBatch.cityTaxes.filter(tax => tax.taxHolderType == TaxHolderType.EMPLOYEE)
		}
		return res;
	}

	private openModal(tax: TaxData): void {
		this.$modal.show(
			TaxRateOverrideModal,
			{
				taxData: tax,
				employment: this.employment,
				onSuccess: () => {
					this.getTaxes()
				}
			}
		)
	}

	private toSelectOptions(list: Array<TaxData>): Array<SelectOption> {
		return list.map(it => SelectOption.builder().title(it.title).value(it).build())
	}

	get businessTaxes(): TaxBatch {
		let res: TaxBatch = new TaxBatch();
		if (this.taxBatch) {
			res.federalTaxes = this.taxBatch.federalTaxes.filter(tax => tax.taxHolderType == TaxHolderType.BUSINESS)
			res.stateTaxes = this.taxBatch.stateTaxes.filter(tax => tax.taxHolderType == TaxHolderType.BUSINESS)
			res.cityTaxes = this.taxBatch.cityTaxes.filter(tax => tax.taxHolderType == TaxHolderType.BUSINESS)
		}
		return res;
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}

	get advancedTaxes(): Array<TaxData> {
		if (this.taxBatch) {
			let res: Array<TaxData> = [];
			res.push(...this.taxBatch.federalTaxes.filter(it => it.defaultTax.title.toLowerCase().indexOf(this.searchableTaxUuid.toLowerCase()) >= 0))
			res.push(...this.taxBatch.stateTaxes.filter(it => it.defaultTax.title.toLowerCase().indexOf(this.searchableTaxUuid.toLowerCase()) >= 0))
			res.push(...this.taxBatch.cityTaxes.filter(it => it.defaultTax.title.toLowerCase().indexOf(this.searchableTaxUuid.toLowerCase()) >= 0))
			return res;
		}
		return [];
	}

	private formatDecimal(num: number): string {
		let arr = (num * 100).toFixed(4).toString().split(".")
		let res = '';
		for (let i = arr[1].length - 1; i >= 0; i--) {
			if (arr[1][i] == '0' && res == '') continue
			res = arr[1][i] + res
		}
		arr[1] = res
		if (arr[1].length > 0) {
			return `${arr[0]},${arr[1]}`
		} else {
			return arr[0]
		}
	}
}

