import FileMetaDTO from "@/dto/files/FileMetaDTO";
import { EmployeesHRDocumentType } from "@/constants/DocumentConstants";

export default class EmployeesHRDocumentsPayload {

    type: EmployeesHRDocumentType | null = null;
    year?: number | null = null;
    state?: string | null = null;
    signDate: Date | null = null;
    file: FileMetaDTO | null = null;

}
