
export interface Tax {
    title: string,
    amount: number | null,
    taxRate: number | null,
    maxThreshold: number | null
}

export interface TaxDefinition {
    uuid: string,
    title: string,
    defaultTax: Tax,
    taxType: TaxType,
    taxHolderType: TaxHolderType
}

export interface TaxData extends TaxDefinition{
    overrideTax: Tax | null
}

export class TaxBatch {
    federalTaxes: Array<TaxData> = [];
    stateTaxes: Array<TaxData> = [];
    cityTaxes: Array<TaxData> = [];
}

export enum TaxType {
    FEDERAL = "FEDERAL",
    STATE = "STATE",
    LOCAL = "LOCAL"
}

export enum TaxHolderType {
    EMPLOYEE = "EMPLOYEE",
    BUSINESS = "BUSINESS"
}